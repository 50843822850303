import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import { 
  useNavigate,
  useParams,
} from "react-router-dom";
import { Redirect } from '@shopify/app-bridge/actions';

import Template from "./Template";

const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function NftSettings(props) {
  const app = props.app;
  const shop = props.shop;
  const redirect = Redirect.create(app);

  const navigate = useNavigate();
  const params = useParams();

  const [store, setStore] = useState(null);
  const [smartContract, setSmartContract] = useState(null);

  // Fulfilment image:
  const [selected, setSelected] = useState(null);

  // NFT Details:
  const [selectedNftDetails, setSelectedNftDetails] = useState(null);

  // Custom Metadata:
  const [selectedCustomMetadata, setSelectedCustomMetadata] = useState(null);




  useEffect(() => {
    checkSessionActive();
    fetchData();



    async function fetchData() {
      await getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/page_contents/nft_settings`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          let payload = result.payload;
          setStore(payload.store);
          setSelected(payload.store.fulfilment.image_type);
          setSelectedNftDetails(payload.store.fulfilment.nft_details_type);
          setSelectedCustomMetadata(payload.store.fulfilment.custom_metadata_type);
        });
  
  
      });

      await getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/smart_contracts/show`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          console.log(`/api/v0/shopify/smart_contracts/show:::2022-07-07`);
          console.log('result:::2022-07-07', result);
          let payload = result.payload;
          setSmartContract(payload.smart_contract);
        });
  
  
      });

    }

    function checkSessionActive() {
      getSessionToken(app).then((data) => {
        let token = data;
        const url = "/api/v0/shopify/sessions/check_session_active";
        let myURL = new URL(url, BASE_URL_RAILS);
        
        var config = {
          method: 'post',
          url: myURL,
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          // data : data
        };
        
        axios(config).then((response) => {
          // console.log("response:::2022-07-19", response);
          var payload = response.data.payload;
          if (payload.message === "Session has expired. Try refreshing your page.") {
            // alert(payload.message);
            redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      })    
    }

  
  }, []);



  // Fulfilment image:
  const handleSelectChange = useCallback((value) => {
    setSelected(value)

    saveFulfilmentType();
  
    function saveFulfilmentType() {
      getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/stores/update_fulfilment_image_type`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        var config = {
          method: 'post',
          url: myUrl,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: {
            fulfilment: {
              image_type: value
            },
          }
        }
  
        axios(config).then((response) => {
          // console.log('response:::2022-06-23', response);
        })
  
      });
    }


  }, []);



  // NFT Details:
  const handleSelectChangeNftDetails = useCallback((value) => {
    setSelectedNftDetails(value)

    saveFulfilmentType();
  
    function saveFulfilmentType() {
      getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/stores/update_fulfilment_nft_details_type`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        var config = {
          method: 'post',
          url: myUrl,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: {
            fulfilment: {
              nft_details_type: value
            },
          }
        }
  
        axios(config).then((response) => {
          // console.log('response:::2022-06-23', response);
        })
  
      });
    }
  }, []);



  // Custom Metadata:
  const handleSelectChangeCustomMetadata = useCallback((value) => {
    setSelected(value)

    saveFulfilmentType();
  
    function saveFulfilmentType() {
      getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/stores/update_fulfilment_custom_metadata`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        var config = {
          method: 'post',
          url: myUrl,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: {
            fulfilment: {
              custom_metadata_type: value
            },
          }
        }
  
        axios(config).then((response) => {
          // console.log('response:::2022-06-27', response);
        })
  
      });
    }
  }, []);
  


  // Manual triggers:
  function collectOrders(app) {
    getSessionToken(app).then((data) => {
      let token = data;

      const url = `/api/v0/shopify/stores/collect_orders`;
      let myUrl = new URL(url, BASE_URL_RAILS);

      var config = {
        method: 'post',
        url: myUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {
          // fulfilment_type: value,
        }
      }

      axios(config).then((response) => {
        console.log('response:::2022-08-02', response);
        alert('Collected orders are now up to date.');
      })

    });
  }
  function collectProducts(app) {
    getSessionToken(app).then((data) => {
      let token = data;

      const url = `/api/v0/shopify/stores/collect_products`;
      let myUrl = new URL(url, BASE_URL_RAILS);

      var config = {
        method: 'post',
        url: myUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {
          // fulfilment_type: value,
        }
      }

      axios(config).then((response) => {
        console.log('response:::2022-08-02', response);
        alert('Collected products are now up to date.');
      })

    });
  }



  return (
    <Template
      app={app}
      store={store}
      smartContract={smartContract}

      // Fulfilment image:
      selected={selected}
      handleSelectChange={handleSelectChange}

      // Manual triggers:
      collectOrders={collectOrders}
      collectProducts={collectProducts}

      // NFT Details:
      selectedNftDetails={selectedNftDetails}
      handleSelectChangeNftDetails={handleSelectChangeNftDetails}

      // Custom Metadata:
      selectedCustomMetadata={selectedCustomMetadata}
      handleSelectChangeCustomMetadata={handleSelectChangeCustomMetadata}

    ></Template>
  );

}



export default NftSettings;