import React from "react";

import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";



const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;
const BASE_URL_MINTER_FRONT = process.env.REACT_APP_BASE_URL_MINTER_FRONT;



function ClaimLink(props) {
  // console.log('ClaimLink:::2022-08-01');
  // console.log('props:::2022-08-01', props);
  const app = props.app;
  const store = props.store;
  const order = props.order;
  const product = props.product;
  const resetClaimStatus = props.resetClaimStatus;

  var emailCount = product.certify_claim_email_count;



  const formEmail = props.formEmail;
  const handleFormSubmit = props.handleFormSubmit;
  const handleFormEmailChange = props.handleFormEmailChange;
  const handleGenerateClaimLink = props.handleGenerateClaimLink;

  var claimLink;
  if (product.claim_link_generated) {
    claimLink = (
      <a
        href={`${BASE_URL_MINTER_FRONT}/minter?uuid=${product.uuid}`}
        target="_blank"
      >
        { `${BASE_URL_MINTER_FRONT}/minter?uuid=${product.uuid}` }
      </a>
    )
  } else {
    claimLink = "Not generated";
  }

  // Claim status:
  var claimStatus;
  if ([null, ""].includes(product.claim_status)) {
    claimStatus = "Not claimed";
  } else {
    claimStatus = product.claim_status;
  }



  let boolLockGenerateLink = (product.claim_link_generated);
  boolLockGenerateLink = boolLockGenerateLink || product.claim_status === 'claimed';
  let boolLockSendLink = product.claim_status === 'claimed';
  boolLockSendLink = boolLockSendLink || !product.claim_link_generated;

  return (
    <Layout.Section>
      <Card title="Claim Link" sectioned>



        <>
          {/* <p><b>Claim status:</b> { product.payload_mint === null ? "Pending" : "Claimed" }</p> */}
          <p><b>Claim status:</b> { claimStatus }</p>
        </>



        <>
          <p><b>Emails sent:</b> { emailCount }</p>
        </>




        <ShowClaimLink product={product}></ShowClaimLink>

        <div style={{paddingTop: '15px'}}>
          <Form onSubmit={(_event) => handleFormSubmit(_event, product.uuid, product.claim_status)}>
            <FormLayout>

              <TextField
                value={formEmail}
                onChange={handleFormEmailChange}
                label="Email address of recipient"
                name="formEmail"
                type="email"
                disabled={boolLockSendLink}
                helpText={
                  <span>
                    We'll use this email address to send the Digital Collectible Claim Link.
                  </span>
                }
              />

              <ButtonGroup>
                <Button 
                  primary 
                  onClick={handleGenerateClaimLink}
                  disabled={boolLockGenerateLink}
                >Confirm and Generate Claim Link</Button>
                <Button 
                  submit 
                  disabled={boolLockSendLink}
                >Send Claim Link to Customer</Button>
              </ButtonGroup>
            </FormLayout>
          </Form>
        </div>

          {/* <Button 
            destructive
            onClick={() => resetClaimStatus()}
          >Reset Claim Status</Button> */}

      </Card>
      <div style={{height: '50px'}}></div>
    </Layout.Section>
  );



  function ShowClaimLink(props) {
    const product = props.product;
    if (product.claim_status === 'claimed') return (<></>);
    return (<p><b>Claim link: </b> { claimLink }</p>);
  }

}



export default ClaimLink;