import React from "react";

import {
  Card,
  Layout,
  Page,
} from "@shopify/polaris";

// Components:
import ResourceListWithBulkActions from "./ResourceListWithBulkActions";

// Styling:
import styles from "./css/Template.module.css";
import ResourceListWithBulkActions2 from "./ResourceListWithBulkActions2";

// Images:



function Template(props) {
  const store = props.store;

  // Resource list 1:
  const resourceList1 = props.resourceList1;
  
  // Resource list 2:
  const resourceList2 = props.resourceList2;



  if (store ===  null) {
    return (
      <>
        <Page>
          <Layout>
            <Layout.Section>
              <Card title="Loading" sectioned>
                <p>Loading...</p>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </>
    )
  }


  return (
    <>
      <Page>
        <Layout>

          <Layout.Section>
            <Card title="Product Setup" sectioned>
              <p>Configure which existing products will offer Digital Collectible options.</p>
            </Card>
          </Layout.Section>



          {/* Product Selection */}
          <Layout.Section>
            <Card title="Standard Products" sectioned>
              <div className="mb-10">
                <p>Select products from this list and click 'Add Product' to allow the product to have a Digital Collectible Option.</p>
              </div>
              <div>
                <ResourceListWithBulkActions
                  resourceList1={resourceList1}
                ></ResourceListWithBulkActions>
              </div>
            </Card>
          </Layout.Section>



          {/* Selected Products */}
          <Layout.Section>
            <Card title="Products with Digital Collectible Options" sectioned>
              <div className="mb-10">
                <p>When customers purchase products which appear on this list, they'll be appear in the New Orders page, ready for Collectible configuration.</p>
              </div>
              <div>
                <ResourceListWithBulkActions2
                  resourceList2={resourceList2}
                ></ResourceListWithBulkActions2>
              </div>
            </Card>
          </Layout.Section>





        </Layout>

      </Page>
    </>
  );

}



export default Template;