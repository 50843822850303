import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
} from "@shopify/polaris";

// Components:

// CSS:



function OrderDetails(props) {
  const order = props.order;
  const orderId = props.orderId;



  return (
    <>
      <Card title="Order Details" sectioned>
        <p>
          <b>ID: </b>
          <Link
            to={`/orders/${orderId}/overview`}
          >
            { orderId }
          </Link>
        </p>
        <p>
          <b>Order number: </b>
          {order.order_number}
        </p>
        <p>
          <b>Email: </b>
          {order.email}
        </p>
        {/* <p>
          <b>Created: </b>
          {order.payload_order.created_at}
        </p>
        <p>
          <b>Currency: </b>
          {order.payload_order.currency}
        </p> */}
        <p>
          <b>Financial status: </b>
          {order.financial_status}
        </p>
        {/* <p>
          <b>Token: </b>
          {order.payload_order.token}
        </p>
        <p>
          <b>Number of products: </b>
          {sumTotalProducts(
            order.payload_order.line_items
          )}
        </p> */}


        {/* <p>
          <b>Title:</b> {product.payload_product.title}
        </p>
        <p>
          <b>Description:</b> {product.payload_product.body_html}
        </p>
        <p>
          <b>Price:</b> {product.payload_line_item.price}
        </p>
        <p>
          <b>SKU:</b> {product.payload_line_item.sku}
        </p>
        <p>
          <b>Vendor:</b> {product.payload_line_item.vendor}
        </p>
        <p>
          <b>Images:</b> {product.payload_product.images.length}
        </p> */}


      </Card>
    </>
  );

}

export default OrderDetails;