import React, { useEffect, useState } from "react";

import { 
  useNavigate,
} from "react-router-dom";

import { getSessionToken } from "@shopify/app-bridge-utils";
import { Redirect } from '@shopify/app-bridge/actions';
import axios from "axios";

// Components:
import Template from "./Template";



const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;
const img = "https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg";

function Home(props) {
  const navigate = useNavigate();
  const app = props.app;
  const shop = props.shop;
  const redirect = Redirect.create(app);

  const [startupGuide, setStartupGuide] = useState(null);
  const [taskCount, setTaskCount] = useState(0);
  const [taskTotal, setTaskTotal] = useState(4);

  useEffect(() => {
    checkSessionActive();
    checkShopInstalled();
    fetchPageData();
    // checkBilling();


    function checkSessionActive() {
      getSessionToken(app).then((data) => {
        let token = data;
        const url = "/api/v0/shopify/sessions/check_session_active";
        let myURL = new URL(url, BASE_URL_RAILS);
        
        var config = {
          method: 'post',
          url: myURL,
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          // data : data
        };
        
        axios(config).then((response) => {
          // console.log("response:::2022-07-19", response);
          var payload = response.data.payload;
          if (payload.message === "Session has expired. Try refreshing your page.") {
            // alert(payload.message);
            redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      })    
    }


    function fetchPageData() {
      getSessionToken(app).then((data) => {
        let token = data;
        const url = "/api/v0/shopify/page_contents/dashboards";
        let myURL = new URL(url, BASE_URL_RAILS);
        
        var config = {
          method: 'get',
          url: myURL,
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          // data : data
        };
        
        axios(config).then((response) => {
          console.log("response:::2022-09-20", response);
          var payload = response.data.payload;
          setStartupGuide(payload.startup_guide.payload);

          // Setup task progress bar:
          setTaskCount(payload.tasks.count);
          setTaskTotal(payload.tasks.total);

        })
        .catch(function (error) {
          console.log(error);
        });
      })    
    }


  }, []);



  function checkShopInstalled() {
    console.log('checkShopInstalled::2022-06-06');
    getSessionToken(app).then((data) => {
      let token = data;
      const url = "/stores/check_shop_installed";
      let myURL = new URL(url, BASE_URL_RAILS);  
      
      var config = {
        method: 'post',
        url: myURL,
        headers: { 
          Authorization: `Bearer ${token}`,
        },
        // data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log('response.data::2022-06-03', response.data);
        if (response.data.is_installed == false) {
          redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    })    
  }


  function checkBilling() {
    getSessionToken(app).then((data) => {
      let token = data;
      const url = "/api/v0/shopify/billing/check_billing";
      let myURL = new URL(url, BASE_URL_RAILS);
      
      var config = {
        method: 'post',
        url: myURL,
        headers: { 
          Authorization: `Bearer ${token}`,
        },
        // data : data
      };
      
      axios(config)
      .then(function (response) {
        var payload = response.data.payload;
      })
      .catch(function (error) {
        console.log(error);
      });
    })    
  }


  return (
    <>
      <Template
        app={app}
        startupGuide={startupGuide}
        taskCount={taskCount}
        taskTotal={taskTotal}
      ></Template>
    </>
  );

}

export default Home;