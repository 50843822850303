import React, { useState, useEffect } from "react";
import { getSessionToken } from "@shopify/app-bridge-utils";
import axios from "axios";

import {
  Card,
  Layout,
} from "@shopify/polaris";

// Components:



const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;




function CurrentPlan(props) {
  // console.log("CurrentPlan::2022-09-26");
  // console.log("props::2022-09-26", props);
  const app = props.app;
  const store = props.store;
  const plan = props.plan;

  // State variables:
  const [currentPlan, setCurrentPlan] = useState(null);



  if (currentPlan === null) return <p>Loading...</p>;
  if (currentPlan.status === 'cancelled') return <></>;
  if (currentPlan.name === '...') return <></>;


  return (
    <>
      <Layout.Section>
        <Card sectioned>
          <p><b>Your current plan: { currentPlan.name }</b></p>
          <p>Plan started on { currentPlan.activated_on }.</p>
          <p>Status: { currentPlan.status }</p>
        </Card>
      </Layout.Section>
    </>
  );

}

export default CurrentPlan;