import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  Layout,
} from "@shopify/polaris";
import { getSessionToken } from "@shopify/app-bridge-utils";
import { Redirect } from '@shopify/app-bridge/actions';
import axios from "axios";

// CSS:
import styles from "./css/Plans.module.css";

// Images:
import imagePurpleTick from "./images/purple-tick.svg";



const img = "https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg";
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function Plans(props) {

  const app = props.app;
  const plan = props.plan;
  const plans = props.plans;
  const triggerPlan1 = plans.triggerPlan1;
  const triggerPlan2 = plans.triggerPlan2;
  const store = props.store;



  function MyButtonPlan1() {
    if (plan && plan.name === 'Starter Plan' && plan.status === 'active') {
      return (
        <Button disabled>Current Plan</Button>
      );      
    } else {
      return (
        <Button 
          onClick={() => triggerPlan1(props)}
          primary
        >Select</Button>
      );
    }
  }


  function MyButtonPlan2() {
    if (plan && plan.name === 'Business Plan' && plan.status === 'active') {
      return (
        <Button disabled>Current Plan</Button>
      );      
    } else {
      return (
        <Button 
          onClick={() => triggerPlan2(props)}
          primary
        >Select</Button>
      );
    }
  }



  function MyButtonPlan3() {
    return (
      <Button 
        primary
      >Contact Us</Button>
    );
  }



  return (
    <>
      <Layout>

        <Layout.Section oneThird>
          <Card title="Starter Plan" sectioned>
            <p>Free</p>

            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>$8.99 Digital Collectible Base Cost</p>
            </div>

            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Free Blockchain Smart Contract creation for your store with your unique OpenSea Collection</p>
            </div>

            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>We pay Digital Collectible creation fees including IPFS storage, minting fees, Customer AirDrop fees, Customer rewards treasury</p>
            </div>

            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Customer included in AirDrop rewards benefit</p>
            </div>

            <div style={{paddingTop: '5px'}}><MyButtonPlan1></MyButtonPlan1></div>
            
          </Card>
        </Layout.Section>



        <Layout.Section oneThird>
          <Card title="Business Plan" sectioned>
            <p>$299 USD every 30 days.</p>

            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>$5.99 Digital Collectible Base Cost</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Free Blockchain Smart Contract creation for your store with your unique OpenSea Collection</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>We pay Digital Collectible creation fees including IPFS storage, minting fees, Customer AirDrop fees, Customer rewards treasury</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Customer included in AirDrop rewards benefit</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Automated Social Media Twitter bot to advertise Digital Collectible sales with product links</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Access to customizable Digital Collectible benefits Dashboard</p>
            </div>

            <div style={{paddingTop: '5px'}}><MyButtonPlan2></MyButtonPlan2></div>

          </Card>
        </Layout.Section>



        <Layout.Section oneThird>
          <Card title="Enterprise" sectioned>
            <p>Contact us</p>

            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Contact us for Digital Collectible Base Cost</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>2.5% Royalty for secondary OpenSea sales</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Free Blockchain Smart Contract creation for your store with your unique OpenSea Collection</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>We pay Digital Collectible creation fees including IPFS storage, minting fees, Customer AirDrop fees, Customer rewards treasury</p>
            </div>
            <div className={styles.planContentText}>
              <img src={imagePurpleTick}></img>
              <p className={styles.planContentp}>Customer included in AirDrop rewards benefit</p>
            </div>


            <MyButtonPlan3></MyButtonPlan3>
          </Card>
        </Layout.Section>
      </Layout>
    </>
  )
}

export default Plans;