import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import { 
  useParams,
} from "react-router-dom";


// Components:
import OrdersOverviewProductTemplate from "./OrdersOverviewProductTemplate";


const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;
// const BASE_URL_MINTER_FRONT = process.env.REACT_APP_BASE_URL_MINTER_FRONT;




function OrdersOverviewProduct(props) {
  const app = props.app;

  const params = useParams();

  // State variables:
  const [store, setStore] = useState(null);
  const [order, setOrder] = useState(null);
  const [product, setProduct] = useState(null);
  const [nftDetails, setNftDetails] = useState(null);
  const [formEmail, setFormEmail] = useState("");


  const orderId = params.orderId;
  const productUUID = params.productId;


  // <-- KW




  const [productImage, setProductImage] = useState({
    url: '...'
  });




  useEffect(() => {

    async function fetchData() {
      await getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/products/show_by_uuid?product_uuid=${productUUID}`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          const payload = result.payload;

          setStore(payload.store);
          setOrder(payload.order);
          setProduct(payload.product);
          setNftDetails(payload.nft_details);
          setFormEmail(payload.order.payload_order.email)

          // if (result.product.image_details == null) {
          //   setProductImage({
          //     url: result.product.payload_product.image.src
          //   });
          // } else {
          //   setProductImage({
          //     url: `${BASE_URL_RAILS}/${result.product.image_details.file_path}`
          //   });
          // }

        });
  
  
      });
    }
  
    fetchData();
  }, []);



  function onClearImage(product) {
    setProduct(product);
  }



  function onFileUpload(product) {
    setProduct(product);
  }



  function resetClaimStatus() {    
    getSessionToken(app).then((data) => {
      let token = data;
  
      const url = `/api/v0/shopify/products/reset_claim_status`;
      let myUrl = new URL(url, BASE_URL_RAILS);
  
      var config = {
        method: 'post',
        url: myUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {
          product_uuid: product.uuid
        }
      }
  
      axios(config).then((response) => {
        const payload = response.data.payload;
        setProduct(payload.product);
      })
  
  
    });
  
  }



  // Form functions:
  const handleFormSubmit = useCallback((_event, product_uuid) => {
    // setFormEmail("");

    // console.log('handleFormSubmit:::2022-07-18');
    var temp = {};
    temp.recipient = _event.target.formEmail.value;

    getSessionToken(app).then((data) => {
      let token = data;
  
      const url = `/api/v0/shopify/products/send_claim_link`;
      let myUrl = new URL(url, BASE_URL_RAILS);
  
      var config = {
        method: 'post',
        url: myUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {
          product_uuid: product_uuid,
          recipient: temp.recipient,
        }
      }
  
      axios(config).then((response) => {
        const payload = response.data.payload;
        setProduct(payload.product);
      })
    });
  }, []);

  const handleFormEmailChange = useCallback((value) => setFormEmail(value), []);



  return (
    <OrdersOverviewProductTemplate
      app={app}
      params={params}
      
      store={store}
      order={order}
      product={product}

      orderId={orderId}
      nftDetails={nftDetails}

      onClearImage={onClearImage}
      onFileUpload={onFileUpload}
      
      resetClaimStatus={resetClaimStatus}

      // Form:
      formEmail={formEmail}
      handleFormSubmit={handleFormSubmit}
      handleFormEmailChange={handleFormEmailChange}

    ></OrdersOverviewProductTemplate>
  );

}

export default OrdersOverviewProduct;