import React, { useCallback, useState } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import {
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";


// Global Variables:
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;
var app;



function CustomMetadata(props) {
  app = props.app;
  const store = props.store;
  const order = props.order;
  const product = props.product;
  const nftDetails = props.nftDetails;



  if (store === null) {
    return (
      <>
        <Layout.Section>
          <Card title="Loading" sectioned>
            <p>Loading...</p>
          </Card>
        </Layout.Section>
      </>
    )
  }



  if (store.fulfilment.custom_metadata_type === "automatic") {    
    return (
      <>

        <Layout.Section>
          <Card title="Custom Metadata" sectioned>
            <p><b>Fulfilment Type:</b> { store.fulfilment.custom_metadata_type }</p>
            <p>Coming soon...</p>
          </Card>
        </Layout.Section>

      </>
    );
  }



  if (store.fulfilment.custom_metadata_type === "manual") {    
    return (
      <>

        <Layout.Section>
          <Card title="Custom Metadata" sectioned>
            <div style={{paddingBottom: '15px'}}>
              <p><b>Fulfilment Type:</b> { store.fulfilment.custom_metadata_type }</p>
            </div>

            <FormCustomMetadata
              app={app}
              product={product}
            ></FormCustomMetadata>

          </Card>
        </Layout.Section>

      </>
    );
  }

}



function FormCustomMetadata(props) {
  const app = props.app;
  const product = props.product;
  const myArray = [1, 2, 3];
  var temp = {};

  // Prep:
  if (product.custom_metadata === null) {
    temp.custom_metadata = [];
  } else {
    temp.custom_metadata = product.custom_metadata;
  }

  // State Variables:
  const [inputFields, setInputFields] = useState(temp.custom_metadata);


  const addFields = () => {
    console.log('addFields:::2022-06-24');
    let newField = { title: '', value: '' };
    setInputFields([...inputFields, newField]);
  }



  const handleFormChange = (index, name, val) => {
    let data = [...inputFields];
    data[index][name] = val;
    setInputFields(data);
  };



  const handleSubmit = useCallback((_event) => {

    let form = [];
    for (let i = 0; i < _event.target.length; i++) {
      const element = _event.target[i];

      if (element.type === 'text') {
        console.log(`id: ${element.id}, name: ${element.name}, value: ${element.value}`)
        let index = parseInt(element.id) - 1;
        if (form[index] === undefined) {
          form[index] = {}
        }
        form[index][element.name] = element.value;
      }
    }

    getSessionToken(app).then((data) => {
      let token = data;

      var config = {
        method: 'post',
        url: `${BASE_URL_RAILS}/api/v0/shopify/products/update_custom_metadata`,
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data : {
          product_uuid: product.uuid,
          form: {
            input_fields: form
          }
        }
      };

      axios(config)
      .then(function (response) {
        var payload = response.data.payload;
      })
      .catch(function (error) {
        console.log(error);
      });
    });

  }, []);



  const removeFields = (index) => {
    let data = [...inputFields];
    data.splice(index, 1);
    setInputFields(data);
  }


  return (
    <>
      <Form onSubmit={handleSubmit}>
        <FormLayout>
        
          {inputFields.map((input, index) => {
            return (
              <FormLayout.Group key={index}>
                <TextField
                  name="title"
                  value={input.title}
                  onChange={ val => handleFormChange(index, 'title', val)}
                  label="Title"
                  autoComplete="off"
                  id={index + 1}
                />

                <TextField
                  name="value"
                  value={input.value}
                  onChange={ val => handleFormChange(index, 'value', val)}
                  label="Value"
                  autoComplete="off"
                  id={index + 1}
                  connectedRight={
                    <Button destructive onClick={() => removeFields(index)}>X</Button>
                  }
                />
              </FormLayout.Group>
            )
          })}

          <FormLayout.Group>
            <div style={{textAlign: 'center'}}>
              <Button onClick={addFields}>+</Button>
            </div>
          </FormLayout.Group>

          <FormLayout.Group>
            <Button submit>Submit</Button>
          </FormLayout.Group>

        </FormLayout>
      </Form>
    </>
  );
}


function FormOnSubmitExample(props) {
  const app = props.app;
  const product = props.product;


  var tempTitle, tempDescription, tempVendor, tempPurchaseDate;
  if (product.manual_fulfilment) {
    tempTitle = product.manual_fulfilment.title;
    tempDescription = product.manual_fulfilment.description;
    tempVendor = product.manual_fulfilment.vendor;
    tempPurchaseDate = product.manual_fulfilment.purchase_date;
  } else {
    tempTitle = '';
    tempDescription = '';
    tempVendor = '';
    tempPurchaseDate = '';
  }

  // State Variables:
  const [title, setTitle] = useState(tempTitle);
  const [description, setDescription] = useState(tempDescription);
  const [vendor, setVendor] = useState(tempVendor);
  const [purchaseDate, setPurchaseDate] = useState(tempPurchaseDate);

  const handleSubmit = useCallback((_event) => {
    var formTitle = _event.target.title.value;
    var formDescription = _event.target.description.value;
    var formVendor = _event.target.vendor.value;
    var formPurchaseDate = _event.target.purchase_date.value;

    getSessionToken(app).then((data) => {
      let token = data;

      var config = {
        method: 'post',
        url: `${BASE_URL_RAILS}/api/v0/shopify/products/update_manual_fulfilment`,
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data : {
          product_uuid: product.uuid,
          form: {
            title: formTitle,
            description: formDescription,
            vendor: formVendor,
            purchase_date: formPurchaseDate,
          }
        }
      };

      axios(config)
      .then(function (response) {
        var payload = response.data.payload;
      })
      .catch(function (error) {
        console.log(error);
      });
    });


  }, []);
    
  const handleTitleChange = useCallback((newValue) => setTitle(newValue), []);
  const handleDescriptionChange = useCallback((newValue) => setDescription(newValue), []);
  const handleVendorChange = useCallback((newValue) => setVendor(newValue), []);
  const handlePurchaseDateChange = useCallback((newValue) => setPurchaseDate(newValue), []);

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>


        <TextField
          name="title"
          value={title}
          onChange={handleTitleChange}
          label="NFT Name"
          autoComplete="off"
        />

        <TextField
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          label="NFT Description"
          autoComplete="off"
        />

        <TextField
          name="vendor"
          value={vendor}
          onChange={handleVendorChange}
          label="Vendor"
          autoComplete="off"
        />

        <TextField
          name="purchase_date"
          value={purchaseDate}
          onChange={handlePurchaseDateChange}
          label="Purchase Date"
          autoComplete="off"
          helpText="Date format: YYYY-MM-DD"
        />


        <Button submit>Submit</Button>
      </FormLayout>
    </Form>
  );
}



export default CustomMetadata;