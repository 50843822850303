import React from "react";
import {
  Button,
  Card,
  DataTable,
  Page,
} from "@shopify/polaris";

// Components:



function Template(props) {
  // console.log("Template::2022-06-27");
  // console.log("props::2022-06-27", props);
  const tableRows = props.tableRows;
  const countClaimedNfts = props.countClaimedNfts;
  const importOrders = props.importOrders;
  const smartContract = props.smartContract;


  if (smartContract === null) {
    return (
      <Page>
        <Card title="Status" sectioned>
          <p>Smart Contract pending.</p>
        </Card>
      </Page>
    );
  }


  let boolLock = countClaimedNfts >= 2;
  return (
    <>
      <Page>



        <Card title="Test Collectibles" sectioned>
          <p>{ countClaimedNfts } of 2 Test Digital Collectible Links Generated.</p>
          <p>Open an existing order to create a Test Digital Collectible on the Certify Smart Contract.</p>
        </Card>

        <div style={{paddingTop: '15px', paddingBottom: '15px'}}>
          <Button 
            onClick={importOrders}
            disabled={boolLock}
          >Import orders</Button>
        </div>




        <Card>
          <DataTable
            columnContentTypes={[
              "numeric",
              "text",
              "text",
              "numeric",
              "text",
            ]}
            headings={[
              "Order",
              "Date",
              "Email",
              "Total Items",
              "Action",
            ]}
            rows={tableRows}
          />
        </Card>



      </Page>
    </>
  );

}

export default Template;