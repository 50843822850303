import React, { useCallback, useState } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import {
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";


// Global Variables:
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;
var app;



function NftDetails(props) {
  app = props.app;
  const store = props.store;
  const order = props.order;
  const product = props.product;
  const nftDetails = props.nftDetails;



  if (store === null) {
    return (
      <>
        <Layout.Section>
          <Card title="Loading" sectioned>
            <p>Loading...</p>
          </Card>
        </Layout.Section>
      </>
    )
  }

  if (store.fulfilment.nft_details_type === "automatic") {    
    return (
      <>

        <Layout.Section>
          <Card title="NFT Details" sectioned>
            <p>
              <b>Fulfilment Type:</b> { store.fulfilment.nft_details_type }
            </p>
            <p>
              <b>NFT Name:</b> { product.payload_product.title }
            </p>
            <p>
              <b>NFT Description:</b> { product.payload_product.body_html }
            </p>
            <p>
              <b>Vendor:</b> { store.payload_shop.name }
            </p>
            <p>
              <b>Purchase Date:</b> { nftDetails.display_date }
            </p>
          </Card>
        </Layout.Section>

      </>
    );
  }

  
  if (store.fulfilment.nft_details_type === "manual") {    
    return (
      <>

        <Layout.Section>
          <Card title="NFT Details" sectioned>
            <p>
              <b>Fulfilment Type:</b> { store.fulfilment.nft_details_type }
            </p>

            <FormOnSubmitExample
              app={app}
              product={product}
            ></FormOnSubmitExample>

          </Card>
        </Layout.Section>

      </>
    );

  }


}



function FormOnSubmitExample(props) {
  const app = props.app;
  const product = props.product;


  var tempTitle, tempDescription, tempVendor, tempPurchaseDate;
  if (product.manual_fulfilment) {
    tempTitle = product.manual_fulfilment.title;
    tempDescription = product.manual_fulfilment.description;
    tempVendor = product.manual_fulfilment.vendor;
    tempPurchaseDate = product.manual_fulfilment.purchase_date;
  } else {
    tempTitle = '';
    tempDescription = '';
    tempVendor = '';
    tempPurchaseDate = '';
  }

  // State Variables:
  const [title, setTitle] = useState(tempTitle);
  const [description, setDescription] = useState(tempDescription);
  const [vendor, setVendor] = useState(tempVendor);
  const [purchaseDate, setPurchaseDate] = useState(tempPurchaseDate);

  const handleSubmit = useCallback((_event) => {
    var formTitle = _event.target.title.value;
    var formDescription = _event.target.description.value;
    var formVendor = _event.target.vendor.value;
    var formPurchaseDate = _event.target.purchase_date.value;

    getSessionToken(app).then((data) => {
      let token = data;

      var config = {
        method: 'post',
        url: `${BASE_URL_RAILS}/api/v0/shopify/products/update_manual_fulfilment`,
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data : {
          product_uuid: product.uuid,
          form: {
            title: formTitle,
            description: formDescription,
            vendor: formVendor,
            purchase_date: formPurchaseDate,
          }
        }
      };

      axios(config)
      .then(function (response) {
        var payload = response.data.payload;
      })
      .catch(function (error) {
        console.log(error);
      });
    });


  }, []);
    
  const handleTitleChange = useCallback((newValue) => setTitle(newValue), []);
  const handleDescriptionChange = useCallback((newValue) => setDescription(newValue), []);
  const handleVendorChange = useCallback((newValue) => setVendor(newValue), []);
  const handlePurchaseDateChange = useCallback((newValue) => setPurchaseDate(newValue), []);

  return (
    <Form onSubmit={handleSubmit}>
      <FormLayout>


        <TextField
          name="title"
          value={title}
          onChange={handleTitleChange}
          label="NFT Name"
          autoComplete="off"
        />

        <TextField
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          label="NFT Description"
          autoComplete="off"
        />

        <TextField
          name="vendor"
          value={vendor}
          onChange={handleVendorChange}
          label="Vendor"
          autoComplete="off"
        />

        <TextField
          name="purchase_date"
          value={purchaseDate}
          onChange={handlePurchaseDateChange}
          label="Purchase Date"
          autoComplete="off"
          helpText="Date format: YYYY-MM-DD"
        />


        <Button submit>Submit</Button>
      </FormLayout>
    </Form>
  );
}



export default NftDetails;