import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Layout,
  Page,
} from "@shopify/polaris";

// CSS:
import styles from "./OrdersOverviewProductTemplate.module.css";

// Components:
import ClaimLink from "./ClaimLink";
import OrderDetails from "./OrdersDetails";

import ImageManagement from "../../components/OrdersOverviewProduct/ImageManagement";
import NftDetails from "../../components/OrdersOverviewProduct/NftDetails";
import CustomMetadata from "../../components/OrdersOverviewProduct/CustomMetadata";



function OrdersOverviewProductTemplate(props) {
  const app = props.app;
  const params = props.params;
  const store = props.store;
  const order = props.order;
  const product = props.product;
  const orderId = props.orderId;
  const nftDetails = props.nftDetails;

  const onClearImage = props.onClearImage;
  const onFileUpload = props.onFileUpload;

  const resetClaimStatus = props.resetClaimStatus;

  // Component: ClaimLink
  const formEmail = props.formEmail;
  const handleFormSubmit = props.handleFormSubmit;
  const handleFormEmailChange = props.handleFormEmailChange;



  if (store === null || order === null || product === null) {
    return (
      <>
        <Page>
          <Layout>
            <Layout.Section>
              <Card title="Loading" sectioned>
                <p>Loading...</p>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </>
    )
  }


  return (
    <>
      <Page>
        <Layout>

          <Layout.Section>
            <OrderDetails
              order={order}
              orderId={orderId}
            ></OrderDetails>
          </Layout.Section>


          
          <NftDetails 
            app={app}
            store={store}
            order={order}
            product={product}
            nftDetails={nftDetails}
          ></NftDetails>



          <CustomMetadata
            app={app}
            store={store}
            order={order}
            product={product}
            nftDetails={nftDetails}          
          ></CustomMetadata>



          <ImageManagement 
            app={app}
            onClearImage={onClearImage}
            onFileUpload={onFileUpload}
            order={order}
            product={product}
            store={store}
          ></ImageManagement>



          <ClaimLink
            app={app}
            store={store}
            order={order}
            product={product}
            resetClaimStatus={resetClaimStatus}

            // Form:
            formEmail={formEmail}
            handleFormSubmit={handleFormSubmit}
            handleFormEmailChange={handleFormEmailChange}
          ></ClaimLink>

        </Layout>

      </Page>
    </>
  );

}

export default OrdersOverviewProductTemplate;