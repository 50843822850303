import React from "react";
import {
  Card,
  Layout,
} from "@shopify/polaris";
import NftDetailsManual from "./NftDetailsManual";



function NftDetails(props) {
  const app = props.app;
  const store = props.store;
  const order = props.order;
  const product = props.product;
  const nftDetails = props.nftDetails;



  if (store === null) {
    return (
      <>
        <Layout.Section>
          <Card title="Loading" sectioned>
            <p>Loading...</p>
          </Card>
        </Layout.Section>
      </>
    )
  }

  if (store.fulfilment.nft_details_type === "automatic") {    
    return (
      <>

        <Layout.Section>
          <Card title="Digital Collectible Details" sectioned>
            <p>
              <b>Fulfilment Type:</b> { store.fulfilment.nft_details_type }
            </p>
            <p>
              <b>Name (Displays on Frame):</b> { product.payload_product.title }
            </p>
            <p>
              <b>Description (Displays on OpenSea):</b> { product.payload_product.body_html }
            </p>
            <p>
              <b>Shop Name (Displays on Frame):</b> { store.payload_shop.name }
            </p>
            <p>
              <b>Date (Displays on Frame):</b> { nftDetails.display_date }
            </p>
          </Card>
        </Layout.Section>

      </>
    );
  }

  
  if (store.fulfilment.nft_details_type === "manual") {    
    return (
      <>

        <NftDetailsManual
          app={app}
          store={store}
          order={order}
          product={product}
          nftDetails={nftDetails}      
        ></NftDetailsManual>

      </>
    );

  }


}



export default NftDetails;