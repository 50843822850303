import React, { useCallback, useState } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import {
  Button,
  Card,
  Form,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";


// Global Variables:
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function NftDetailsManual(props) {
  const app = props.app;
  const countClaimedNfts = props.countClaimedNfts;
  const store = props.store;
  // const order = props.order;
  const product = props.product;
  // const nftDetails = props.nftDetails;


  var tempTitle, tempDescription, tempVendor, tempPurchaseDate;
  tempTitle = product.manual_fulfilment.title || '';
  tempDescription = product.manual_fulfilment.description || '';
  tempVendor = product.manual_fulfilment.vendor || '';
  tempPurchaseDate = product.manual_fulfilment.purchase_date || '';


  // State Variables:
  const [title, setTitle] = useState(tempTitle);
  const [description, setDescription] = useState(tempDescription);
  const [vendor, setVendor] = useState(tempVendor);
  const [purchaseDate, setPurchaseDate] = useState(tempPurchaseDate);

  const handleSubmit = useCallback((_event) => {
    var formTitle = _event.target.title.value;
    var formDescription = _event.target.description.value;
    var formVendor = _event.target.vendor.value;
    var formPurchaseDate = _event.target.purchase_date.value;

    getSessionToken(app).then((data) => {
      let token = data;

      var config = {
        method: 'post',
        url: `${BASE_URL_RAILS}/api/v0/shopify/test_nft_products/update_manual_fulfilment`,
        headers: { 
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data : {
          product_uuid: product.uuid,
          form: {
            title: formTitle,
            description: formDescription,
            vendor: formVendor,
            purchase_date: formPurchaseDate,
          }
        }
      };

      axios(config)
      .then(function (response) {
        var payload = response.data.payload;
      })
      .catch(function (error) {
        console.log(error);
      });
    });


  }, []);
    
  const handleTitleChange = useCallback((newValue) => setTitle(newValue), []);
  const handleDescriptionChange = useCallback((newValue) => setDescription(newValue), []);
  const handleVendorChange = useCallback((newValue) => setVendor(newValue), []);
  const handlePurchaseDateChange = useCallback((newValue) => setPurchaseDate(newValue), []);




  let boolLock = ((product.claim_status === 'claimed') || (countClaimedNfts >= 2));
  return (
    <>

      <Layout.Section>
        <Card title="Digital Collectible Details" sectioned>

          <Form onSubmit={handleSubmit}>
            <FormLayout>


              <TextField
                // name=""
                // value={store.fulfilment.nft_details_type}
                value="Manual"
                // onChange={}
                label="Fulfilment Type"
                // autoComplete="off"
                disabled
              />

              <TextField
                name="title"
                value={title}
                onChange={handleTitleChange}
                label="Name (Displays on Frame)"
                autoComplete="off"
                disabled={boolLock}
              />

              <TextField
                name="description"
                value={description}
                onChange={handleDescriptionChange}
                label="Description (Displays on OpenSea)"
                autoComplete="off"
                disabled={boolLock}
              />

              <TextField
                name="vendor"
                value={vendor}
                onChange={handleVendorChange}
                label="Shop Name (Displays on Frame)"
                autoComplete="off"
                disabled={boolLock}
              />

              <TextField
                name="purchase_date"
                value={purchaseDate}
                onChange={handlePurchaseDateChange}
                label="Date (Displays on Frame)"
                autoComplete="off"
                helpText="Date format: YYYY-MM-DD"
                disabled={boolLock}
              />


              <Button 
                submit 
                primary
                disabled={boolLock}
              >Save</Button>
            </FormLayout>
          </Form>
          

        </Card>
      </Layout.Section>

    </>
  );

}



export default NftDetailsManual;