import React from "react";

import {
  Card,
  Image,
  Layout,
  Page,
} from "@shopify/polaris";

// Components:
import SmartContractDetails from "./SmartContractDetails";
import SmartContractDetailsDeployed from "./SmartContractDetailsDeployed";
import SmartContractDetailsSubmitted from "./SmartContractDetailsSubmitted";

// Images:
import imageSmartContract1DisplayExample from "./images/smart-contract-1-display-example.png"



function Template(props) {
  const app = props.app;
  const store = props.store;
  const smartContract = props.smartContract;

  // Variables:

  // Functions:
  const handleSubmit = props.handleSubmit;
  
  // Smart contract details:
  const contractName = props.contractName;
  const contractSymbol = props.contractSymbol;
  const handleContractNameChange = props.handleContractNameChange;
  const handleContractSymbolChange = props.handleContractSymbolChange;



  if (store ===  null) {
    return (
      <>
        <Page>
          <Layout>
            <Layout.Section>
              <Card title="Loading" sectioned>
                <p>Loading...</p>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </>
    )
  }


  return (
    <>
      <Page>
        <Layout>

          <SmartContractDetails
            contractName={contractName}
            contractSymbol={contractSymbol}
            handleContractNameChange={handleContractNameChange}
            handleContractSymbolChange={handleContractSymbolChange}
            handleSubmit={handleSubmit}
            smartContract={smartContract}
          ></SmartContractDetails>



          <SmartContractDetailsSubmitted
            contractName={contractName}
            contractSymbol={contractSymbol}
            smartContract={smartContract}
          ></SmartContractDetailsSubmitted>



          <SmartContractDetailsDeployed
            contractName={contractName}
            contractSymbol={contractSymbol}
            smartContract={smartContract}
          ></SmartContractDetailsDeployed>

        </Layout>

      </Page>
    </>
  );

}



export default Template;