import React, { useEffect, useState } from "react";

import { 
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

import { getSessionToken } from "@shopify/app-bridge-utils";
import Template from "./Template";


const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function sumTotalProducts(lineItems) {
  var totalProducts = 0;
  lineItems.forEach((l) => {
    totalProducts += l.quantity;
  });
  return totalProducts;
}




function prepareOrderProducts(my_products, order_id) {
  var myRowsTemp = [];
  var count = 1;
  my_products.forEach((p) => {
    let claimStatus = 'Pending';
    if (p.claim_status === 'claimed') claimStatus = 'Claimed';

    myRowsTemp.push([
      p.payload_product.title,
      `$${p.payload_line_item.price}`,
      claimStatus,
      <Link
        to={`/new_orders/${order_id}/product/${p.uuid}`}
      >
        Open
      </Link>,
    ]);
    count++;
  });
  console.log("myRowsTemp", myRowsTemp);
  return myRowsTemp;
}



function Order(props) {
  // console.log("Order::2022-06-02");
  // console.log("props::2022-06-02", props);
  const app = props.app;
  const navigate = useNavigate();
  const params = useParams();
  const [tableRows, setTableRows] = useState([]);
  const [storeOrder, setStoreOrder] = useState({
    email: "",
    financial_status: "",
    order_number: "",
    payload_order: {
      created_at: "",
      currency: "",
      token: "",
      line_items: [],
    }
  });
  // console.log('params:2022-06-02', params);
  // console.log('params.orderId:2022-06-02', params.orderId);
  const orderId = params.orderId;


  useEffect(() => {

    async function fetchData() {
      var token;
      await getSessionToken(app).then((data) => {
        // console.log("getSessionToken()::2022-06-02");
        token = data;
        // console.log("token::2022-06-02", token);
  
        const url = `/api/v0/shopify/page_contents/new_order_orders/${orderId}`;
        let myUrl = new URL(url, BASE_URL_RAILS);
        // console.log("myUrl::2022-06-02", myUrl);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          // console.log("result::2022-07-27", result);
          const payload = result.payload;

          setStoreOrder(payload.order);
          let temp = prepareOrderProducts(payload.products, orderId);
          setTableRows(temp);

        });
  
  
      });
    }
  
    fetchData();
  }, []);



  return (
    <>
      <Template
        orderId={orderId}
        storeOrder={storeOrder}
        tableRows={tableRows}
        sumTotalProducts={sumTotalProducts}
      ></Template>
    </>
  );

}

export default Order;