import React, { useEffect, useState } from "react";

import { 
  Link,
  useNavigate,
} from "react-router-dom";

import {
  Card,
  DataTable,
  EmptyState,
  Heading,
  Layout,
  Page,
  Stack,
} from "@shopify/polaris";
import { getSessionToken } from "@shopify/app-bridge-utils";


const img = "https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg";
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;





function DataTableProducts(props) {
  console.log("DataTableProducts::2022-06-02");
  console.log("props::2022-06-02", props);

  return (
    <Page>
      <Card>
        <DataTable
          columnContentTypes={[
            "text",
            // "text",
            "text",
            "numeric",
            "numeric",
            "text",
          ]}
          headings={[
            "Order",
            // "UUID",
            "Product",
            "Price",
            "SKU Number",
            "Email Sent",
          ]}
          rows={props.tableRows}
        />
      </Card>
    </Page>
  );
}




function PurchasedProducts(props) {
  console.log("PurchasedProducts::2022-06-02");
  console.log("props::2022-06-02", props);
  const app = props.app;
  const navigate = useNavigate();
  const [tableRows, setTableRows] = useState([]);


  useEffect(() => {

    async function fetchData() {
      var token;
      await getSessionToken(app).then((data) => {
        console.log("getSessionToken()::2022-06-02");
        token = data;
        console.log("token::2022-06-02", token);
  
        // const baseURL = props.app.localOrigin;
        const url = "/products/purchased_products";
        let myUrl = new URL(url, BASE_URL_RAILS);
        console.log("myUrl::2022-06-02", myUrl);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          console.log("result::2022-06-02", result);


          // DataTableProducts start:
          var tableRowsTemp = [];

          result.purchased_products.forEach((p) => {
            tableRowsTemp.push([
              p.payload_order.order_number,
              // p.uuid,
              p.payload_product.title,
              p.payload_line_item.price,
              p.payload_line_item.sku,
              // checkCertifyConfigured(p),
              // p.certify_verified[0].toUpperCase() + p.certify_verified.substring(1),
              // p.certify_processed_verification ? "Processed" : "Pending",
              p.certify_claim_email_sent ? "Yes" : "No",
            ]);
          });

          // Sort array:
          var sortedArray = tableRowsTemp.sort(function (a, b) {
            return b[0] - a[0];
          });

          console.log("tableRowsTemp::2022-06-02", tableRowsTemp);
          console.log("sortedArray::2022-06-02", sortedArray);
          setTableRows(sortedArray);
          // DataTableProducts end:

        });
  
  
      });
    }
  
    fetchData();

    setTableRows([
      [
        "Order",
        "UUID",
        "Product",
        123,
        321,
        "Certify Verified",
        "Certify Processed",
        "Email Sent",
      ]
    ]);
    
    

  }, []);



  return (
    <>
      <Page>
        <Card title="Purchased Products Table" sectioned>
          <DataTableProducts
            tableRows={tableRows}
          ></DataTableProducts>
        </Card>
      </Page>
    </>
  );

}

export default PurchasedProducts;