import React from "react";

import {
  Button,
  ButtonGroup,
  Card,
  Image,
  Layout,
  Page,
  Select,
} from "@shopify/polaris";

// Components:


// Images:
import imageCollectibleSettingsDisplayExample from "./images/collectible-settings-display-example.png"



function Template(props) {
  const app = props.app;
  const store = props.store;

  // Functions:
  const collectOrders = props.collectOrders;
  const collectProducts = props.collectProducts;


  // Fulfilment image:
  const selected = props.selected;
  const handleSelectChange = props.handleSelectChange;
  const options = [
    {label: 'Automatic', value: 'automatic'},
    {label: 'Manual', value: 'manual'},
  ];



  // NFT Details:
  const selectedNftDetails = props.selectedNftDetails;
  const handleSelectChangeNftDetails = props.handleSelectChangeNftDetails;
  const optionsNftDetails = [
    {label: 'Automatic', value: 'automatic'},
    {label: 'Manual', value: 'manual'},
  ];
  
  
  
  // Custom Metadata:
  const selectedCustomMetadata = props.selectedCustomMetadata;
  const handleSelectChangeCustomMetadata = props.handleSelectChangeCustomMetadata;
  const optionsCustomMetadata = [
    {label: 'Automatic', value: 'automatic'},
    {label: 'Manual', value: 'manual'},
  ];
  


  if (store ===  null) {
    return (
      <>
        <Page>
          <Layout>
            <Layout.Section>
              <Card title="Loading" sectioned>
                <p>Loading...</p>
              </Card>
            </Layout.Section>
          </Layout>
        </Page>
      </>
    )
  }


  return (
    <>
      <Page>
        <Layout>



          {/* <Layout.Section>
            <Card title="Manual Triggers (Temporary)" sectioned>

              <p>Use the following to retrieve new orders and products:</p>

              <div style={{paddingTop: '15px'}}>
                <ButtonGroup>
                  <Button onClick={() => collectOrders(app)}>Collect Orders</Button>
                  <Button onClick={() => collectProducts(app)}>Collect Products</Button>
                </ButtonGroup>
              </div>        

            </Card>
          </Layout.Section> */}
          


          <Layout.Section>
            <Card title="Collectibles Settings" sectioned>
              <p>Set how your store's Digital Collectibles data will be populated.</p>

              <div>
                <p><b>Automatic (coming soon)</b> The Digital Collectible's data will populate according to the information found on your product's page.</p>
              </div>

              <hr></hr>

              <div>
                <p><b>Manual</b> Allows you to open an order and manually edit the Digital Collectible data fields. The Smart Contract name cannot be modified.</p>
              </div>

              <hr></hr>
            </Card>
          </Layout.Section>



          <Layout.Section>
            <Card sectioned title="Digital Collectibles Fulfilment Type">



              {/* Fulfilment image: */}
              <div>
                <p><b>Image (Displays on Frame)</b></p>
                <p>If set to Automatic, the customer can choose an image from your product's gallery to use as the Digital Collectible Image when they claim their Digital Collectible.</p>

                <div style={{paddingTop: '15px'}}>
                  <Select
                    label="Fulfilment Type"
                    options={options}
                    onChange={handleSelectChange}
                    value={selected}
                    disabled
                  />
                </div>    
              </div>



              {/* Details */}
              <div style={{paddingTop: '30px'}}>
                <p><b>Details (Displays on Frame)</b></p>
                <p>If set to Automatic, the Product Name and Purchase Date will populate the Digital Collectible Title and Digital Collectible Date respectively.</p>

                <div style={{paddingTop: '15px'}}>
                  <Select
                    label="Fulfilment Type"
                    options={optionsNftDetails}
                    onChange={handleSelectChangeNftDetails}
                    value={selectedNftDetails}
                    disabled
                  />
                </div>
              </div>



              {/* Description */}
              <div style={{paddingTop: '30px'}}>
                <p><b>Description (Displays on OpenSea)</b></p>
                <p>Coming soon!</p>
                {/* <p>If set to Automatic, the Product Description will be used on the Digital Collectible.</p> */}
                {/* <p>Fulfilment type</p> */}
                {/* <select> */}
                  {/* <option>Placeholder</option> */}
                {/* </select> */}
              </div>



              {/* Custom Metadata */}
              <div style={{paddingTop: '30px'}}>
                <p><b>Custom Metadata (Displays on OpenSea)</b></p>
                <p>If set to Automatic, the product's variants will be used (e.g. Size, Colour, Material).</p>

                <div style={{paddingTop: '15px'}}>
                  <Select
                    label="Fulfilment Type"
                    options={optionsCustomMetadata}
                    onChange={handleSelectChangeCustomMetadata}
                    value={selectedCustomMetadata}
                    disabled
                  />
                </div>
              </div>



            </Card>
          </Layout.Section>



          <Layout.Section secondary>
            <Card title="Display examples" sectioned>
              <p>A visual example of the Digital Collectible Settings on OpenSea.</p>
              <div>
                <Image
                  src={imageCollectibleSettingsDisplayExample}
                  width="100%"
                ></Image>
              </div>
            </Card>
          </Layout.Section>


        </Layout>

      </Page>
    </>
  );

}



export default Template;