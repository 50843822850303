import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import { 
  useNavigate,
  useParams,
} from "react-router-dom";
import { Redirect } from '@shopify/app-bridge/actions';

// Components:
import Template from "./Template";

const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function SmartContract(props) {
  const app = props.app;
  const shop = props.shop;
  const redirect = Redirect.create(app);

  const navigate = useNavigate();
  const params = useParams();

  // State Variables:
  const [store, setStore] = useState(null);
  const [smartContract, setSmartContract] = useState(null);


  // Smart contract details:
  const [contractName, setContractName] = useState('');
  const [contractSymbol, setContractSymbol] = useState('');



  useEffect(() => {
    checkSessionActive();
    fetchData();



    async function fetchData() {
      await getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/page_contents/smart_contract`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          let payload = result.payload;
          setStore(payload.store);
        });
  
  
      });

      await getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/smart_contracts/show`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        fetch(myUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => response.json()).then((result) => {
          console.log(`/api/v0/shopify/smart_contracts/show:::2022-07-07`);
          console.log('result:::2022-07-07', result);
          let payload = result.payload;
          setSmartContract(payload.smart_contract);
        });
  
  
      });

    }

    function checkSessionActive() {
      getSessionToken(app).then((data) => {
        let token = data;
        const url = "/api/v0/shopify/sessions/check_session_active";
        let myURL = new URL(url, BASE_URL_RAILS);
        
        var config = {
          method: 'post',
          url: myURL,
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          // data : data
        };
        
        axios(config).then((response) => {
          // console.log("response:::2022-07-19", response);
          var payload = response.data.payload;
          if (payload.message === "Session has expired. Try refreshing your page.") {
            // alert(payload.message);
            redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      })    
    }

    getSessionToken(app).then((data) => {
      let token = data;

      const url = `/api/v0/shopify/smart_contracts/show`;
      let myUrl = new URL(url, BASE_URL_RAILS);

      fetch(myUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => response.json()).then((result) => {
        console.log(`/api/v0/shopify/smart_contracts/show:::2022-07-07`);
        console.log('result:::2022-07-07', result);
        let payload = result.payload;
        setSmartContract(payload.smart_contract);
        setContractName(payload.smart_contract.contract_name);
        setContractSymbol(payload.smart_contract.contract_symbol);
      });


    });

  
  }, []);



  // Smart contract details:
  const handleContractNameChange = useCallback((value) => setContractName(value), []);
  const handleContractSymbolChange = useCallback((value) => setContractSymbol(value), []);
  const handleSubmit = useCallback((_event) => {
    console.log('handleSubmit:::2022-06-29');
    console.log('_event:::2022-06-29', _event);
    console.log('_event.target:::2022-06-29', _event.target);
    console.log('_event.target.contractName:::2022-06-29', _event.target.contractName);
    console.log('_event.target.contractName.value:::2022-06-29', _event.target.contractName.value);
    // setContractName("");

    let contractName = _event.target.contractName.value;
    let contractSymbol = _event.target.contractSymbol.value;

    saveSmartContractSetup();
  
    function saveSmartContractSetup() {
      getSessionToken(app).then((data) => {
        let token = data;
  
        const url = `/api/v0/shopify/smart_contracts/update_contract_details`;
        let myUrl = new URL(url, BASE_URL_RAILS);
  
        var config = {
          method: 'post',
          url: myUrl,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: {
            smart_contract_details: {
              contract_name: contractName,
              contract_symbol: contractSymbol,
            },
          }
        }
  
        axios(config).then((response) => {
          // console.log('response:::2022-07-20', response);
          // alert("Form details have been saved!")
          let payload = response.data.payload;

          setSmartContract(payload.smart_contract);
          // setContractName(payload.smart_contract.contract_name);
          // setContractSymbol(payload.smart_contract.contract_symbol);

        })
  
      });
    }


  }, []);



  return (
    <Template
      app={app}
      store={store}
      smartContract={smartContract}
      handleSubmit={handleSubmit}

      // Smart contract details:
      contractName={contractName}
      contractSymbol={contractSymbol}
      handleContractNameChange={handleContractNameChange}
      handleContractSymbolChange={handleContractSymbolChange}

    ></Template>
  );

}



export default SmartContract;