import React from "react";

import {
  Card,
  Layout,
} from "@shopify/polaris";

// Components:
import CustomMetadataManual from "./CustomMetadataManual";



function CustomMetadata(props) {
  const app = props.app;
  const countClaimedNfts = props.countClaimedNfts;
  const store = props.store;
  const product = props.product;



  if (store === null) {
    return (
      <>
        <Layout.Section>
          <Card title="Loading" sectioned>
            <p>Loading...</p>
          </Card>
        </Layout.Section>
      </>
    )
  }



  if (store.fulfilment.custom_metadata_type === "automatic") {    
    return (
      <>

        <Layout.Section>
          <Card title="Custom Metadata" sectioned>
            <p><b>Fulfilment Type:</b> { store.fulfilment.custom_metadata_type }</p>
            <p>Coming soon...</p>
          </Card>
        </Layout.Section>

      </>
    );
  }



  if (store.fulfilment.custom_metadata_type === "manual") {    
    return (
      <>
        <CustomMetadataManual
          app={app}
          countClaimedNfts={countClaimedNfts}
          store={store}
          product={product}
        ></CustomMetadataManual>
      </>
    );
  }

}



export default CustomMetadata;