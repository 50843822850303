import {
  Card,
} from "@shopify/polaris";



function ShopifyImages(props) {
  const product = props.product;

  var productPayload = product.payload_product;

  const shopifyImages = product.shopify_images.map((i, index) =>
    <img key={index} src={`https://${i.base_url}/${i.file_path}`} style={
      {
        width: "50%",
        paddingLeft: "2.5px",
        paddingRight: "2.5px"
      }
    }></img>
  )

  return (
    <>
      <Card title="Shopify Images" sectioned>
        <div>
          <p>
            Number of Images: { productPayload.images.length }
          </p>
          <div>
            { shopifyImages }
          </div>
        </div>
      </Card>
    </>
  );
}

export default ShopifyImages;