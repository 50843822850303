import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge-utils";

import { 
  useNavigate,
  useParams,
} from "react-router-dom";
import { Redirect } from '@shopify/app-bridge/actions';

// Components:
import Template from "./Template";

const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function ProductSetup(props) {
  const app = props.app;
  const shop = props.shop;
  const redirect = Redirect.create(app);

  const navigate = useNavigate();
  const params = useParams();

  // State variables:

  // Resource list 1:
  const [resourceList1Items, setResourceList1Items] = useState([]);
  const [resourceList1SelectedItems, setResourceList1SelectedItems] = useState([]);
  
  // Resource list 2:
  const [resourceList2Items, setResourceList2Items] = useState([]);
  const [resourceList2SelectedItems, setResourceList2SelectedItems] = useState([]);

  const resourceList1 = {};
  const resourceList2 = {};


  useEffect(() => {
    retrieveShopifyProducts();

    function retrieveShopifyProducts() {
      console.log('retrieveShopifyProducts():::2022-07-22');
      getSessionToken(app).then((data) => {
        let token = data;
        const url = "/api/v0/shopify/page_contents/product_setup";
        let myURL = new URL(url, BASE_URL_RAILS);
        
        var config = {
          method: 'get',
          url: myURL,
          headers: { 
            Authorization: `Bearer ${token}`,
          },
          // data : data
        };
        
        axios(config).then((response) => {
          console.log("response:::2022-07-22", response);
          var payload = response.data.payload;
          setResourceList1Items(payload.shopify_products);
          setResourceList2Items(payload.selected_products);
          // if (payload.message === "Session has expired. Try refreshing your page.") {
          //   // alert(payload.message);
          //   redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
          // }
        })
        .catch(function (error) {
          console.log(error);
        });
      })    
    };

  }, []);



  resourceList1.resourceName = {
    singular: "product",
    plural: "products",
  };
  resourceList2.resourceName = {
    singular: "product",
    plural: "products",
  };


  resourceList1.promotedBulkActions = [
    {
      content: "Add products",
      onAction: () => {
        console.log("Todo: implement bulk add products");

        // Add selected items to resourceList2Items.
        let temp = []
        for (let i = 0; i < resourceList1Items.length; i++) {
          const element = resourceList1Items[i];
          if (resourceList1SelectedItems.includes(element.id)) {
            temp.push(element);
          }
        }
        setResourceList2Items([...resourceList2Items, ...temp]);
        addShopifyProduct(temp);


        // Remove product from resourceList1Items.
        temp = {};
        for (let i = 0; i < resourceList1SelectedItems.length; i++) {
          const element = resourceList1SelectedItems[i];
          temp.products = resourceList1Items;
          for (let j = 0; j < temp.products.length; j++) {
            const r = temp.products[j];
            if (r.id === element) {
              temp.products.splice(j, 1);
              break;
            }
          }
        }
        setResourceList1Items(temp.products);
        setResourceList1SelectedItems([]);


        function addShopifyProduct(productArray) {
          // console.log('addShopifyProduct():::2022-07-26');
          getSessionToken(app).then((data) => {
            let token = data;
            const url = "/api/v0/shopify/certify_products";
            let myURL = new URL(url, BASE_URL_RAILS);
            
            var headers = { 
              Authorization: `Bearer ${token}`,
            };
            var data = {
              shopify_product_ids: productArray
            };
    
            axios.post(myURL, data, {headers: headers}).then((response) => {
              // console.log("response:::2022-07-22", response);
              var payload = response.data.payload;
              // setResourceList1Items(payload.shopify_products);
              // if (payload.message === "Session has expired. Try refreshing your page.") {
              //   // alert(payload.message);
              //   redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
              // }
            })
            .catch(function (error) {
              console.log(error);
            });
          })    
        };


      },
    },
  ];
  resourceList2.promotedBulkActions = [
    {
      content: "Remove products",
      onAction: () => {
        console.log("Todo: implement bulk remove products");

        // Add selected items to resourceList1Items.
        let temp = []
        for (let i = 0; i < resourceList2Items.length; i++) {
          const element = resourceList2Items[i];
          if (resourceList2SelectedItems.includes(element.id)) {
            temp.push(element);
          }
        }
        setResourceList1Items([...resourceList1Items, ...temp]);
        removeShopifyProduct(temp);

        // Remove product from resourceList2Items.
        temp = {};
        for (let i = 0; i < resourceList2SelectedItems.length; i++) {
          const element = resourceList2SelectedItems[i];
          temp.products = resourceList2Items;
          for (let j = 0; j < temp.products.length; j++) {
            const r = temp.products[j];
            if (r.id === element) {
              temp.products.splice(j, 1);
              break;
            }
          }
        }
        setResourceList2Items([...temp.products]);
        setResourceList2SelectedItems([]);


        function removeShopifyProduct(productArray) {
          // console.log('removeShopifyProduct():::2022-08-15');
          // console.log('productArray:::2022-08-15', productArray);

          getSessionToken(app).then((data) => {
            let token = data;
            const url = "/api/v0/shopify/certify_products/destroy_by_shopify_product_ids";
            let myURL = new URL(url, BASE_URL_RAILS);
            
            var headers = { 
              Authorization: `Bearer ${token}`,
            };
            var data = {
              shopify_product_ids: productArray
            };
    
            axios.post(myURL, data, {headers: headers}).then((response) => {
              // console.log("response:::2022-08-15", response);
            })
            .catch(function (error) {
              console.log(error);
            });
          })    
        };


      },
    },
  ];


  
  resourceList1.bulkActions = [
    {
      content: "Add tags",
      onAction: () => console.log("Todo: implement bulk add tags"),
    },
    {
      content: "Remove tags",
      onAction: () => console.log("Todo: implement bulk remove tags"),
    },
    {
      content: "Delete customers",
      onAction: () => console.log("Todo: implement bulk delete"),
    },
  ];
  resourceList2.bulkActions = [
    {
      content: "Add tags",
      onAction: () => console.log("Todo: implement bulk add tags"),
    },
    {
      content: "Remove tags",
      onAction: () => console.log("Todo: implement bulk remove tags"),
    },
    {
      content: "Delete customers",
      onAction: () => console.log("Todo: implement bulk delete"),
    },
  ];

  

  function removeSelectedProduct(p, index) {
    // console.log('removeSelectedProduct():::2022-07-27');
    let temp = {};
    temp.resourceList2Items = resourceList2Items;
    temp.resourceList2Items.splice(index, 1);
    setResourceList2Items([...temp.resourceList2Items]);
    removeShopifyProduct(p);


    function removeShopifyProduct(p) {
      // console.log('addShopifyProduct():::2022-07-26');
      getSessionToken(app).then((data) => {
        let token = data;
        const url = "/api/v0/shopify/certify_products/destroy_by_shopify_product_id";
        let myURL = new URL(url, BASE_URL_RAILS);
        
        var headers = { 
          Authorization: `Bearer ${token}`,
        };
        var data = {
          shopify_product_id: p.id,
          id: p.id,
        };

        axios.post(myURL, data, {headers: headers}).then((response) => {
          // console.log("response:::2022-07-27", response);
          var payload = response.data.payload;
          // setResourceList1Items(payload.shopify_products);
          // if (payload.message === "Session has expired. Try refreshing your page.") {
          //   // alert(payload.message);
          //   redirect.dispatch(Redirect.Action.REMOTE, `${BASE_URL_RAILS}/login?shop=${shop}`);
          // }
        })
        .catch(function (error) {
          console.log(error);
        });
      })    
    };

  }

  // Resource list 1: Product Selection
  // resourceList1.bulkActions = resourceList1.bulkActions;
  resourceList1.items = resourceList1Items;
  // resourceList1.promotedBulkActions = resourceList1.promotedBulkActions;
  // resourceList1.resourceName = resourceList1.resourceName;
  resourceList1.selectedItems = resourceList1SelectedItems;
  resourceList1.setResourceList1SelectedItems = setResourceList1SelectedItems;

  // Resource list 2: Selected Products
  // resourceList2.bulkActions = resourceList2.bulkActions;
  resourceList2.items = resourceList2Items;
  // resourceList2.promotedBulkActions = resourceList2.promotedBulkActions;
  // resourceList2.resourceName = resourceList2.resourceName;
  resourceList2.selectedItems = resourceList2SelectedItems;
  resourceList2.setResourceList2SelectedItems = setResourceList2SelectedItems;
  
  resourceList2.removeSelectedProduct = removeSelectedProduct;

  return (
    <Template
      resourceList1={resourceList1}
      resourceList2={resourceList2}
    ></Template>
  );

}



export default ProductSetup;