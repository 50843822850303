import React, { useEffect, useState } from "react";

import { 
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

import { getSessionToken } from "@shopify/app-bridge-utils";
import { Redirect } from '@shopify/app-bridge/actions';
import axios from "axios";

// Components:
import Template from "./Template";

const img = "https://cdn.shopify.com/s/files/1/0757/9955/files/empty-state.svg";
const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function Billing(props) {
  // console.log("Billing::2022-08-18");
  // console.log("props::2022-08-18", props);
  const app = props.app;
  const navigate = useNavigate();
  const params = useParams();
  const location = props.location;

  // State variables:
  const [isLoading, setLoading] = useState(true);
  const [billingActive, setBillingActive] = useState(false);
  const [store, setStore] = useState(null);
  const [plan, setPlan] = useState(null);



  useEffect(() => {
    fetchData();
    setLoading(false);

    async function fetchData() {
      // console.log("fetchData()::2022-06-09");
      const url = "/api/v0/shopify/page_contents/billing";
      let myUrl = new URL(url, BASE_URL_RAILS);
    
      await getSessionToken(app).then((data) => {
        let token = data;
    
        var data = JSON.stringify({});
        
        var config = {
          method: 'get',
          url: myUrl,
          headers: { 
            Authorization: `Bearer ${token}`,
          }
        };
        
        axios(config)
        .then(function (response) {
          // console.log("response::2022-08-18", response);
          let payload = response.data.payload;
          // console.log('payload::2022-09-26', payload);
          setStore(payload.store);
          if (payload.recurring_application_charge === null) {
            setBillingActive(false);
          } else if (payload.recurring_application_charge.status == 'pending') {
            setBillingActive(false);
          } else {
            setBillingActive(true);
          }
          // Retrieve recurring application charge:
          setPlan(payload.recurring_application_charge);
  
        })
        .catch(function (error) {
          console.log(error);
        });
    
      });
  
    }

  }, []);



  // Starter Plan
  async function triggerPlan1(props) {
    console.log("triggerPlan1::2022-08-16");
    console.log("props::2022-08-16", props);

    const app = props.app;
    const redirect = Redirect.create(app);
  
    const url = "/api/v0/shopify/billing/trigger_plan_1";
    let myUrl = new URL(url, BASE_URL_RAILS);
  
    await getSessionToken(app).then((data) => {
      let token = data;
  
      var data = JSON.stringify({});
      
      var config = {
        method: 'post',
        url: myUrl,
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        // console.log('response:::2022-08-18', response);
        let payload = response.data.payload;
        redirect.dispatch(Redirect.Action.REMOTE, payload.confirmation_url);
  
  
      })
      .catch(function (error) {
        console.log(error);
      });
  
    });
  
  }



  // Business Plan
  async function triggerPlan2(props) {
    // console.log("triggerPlan2::2022-08-16");
    // console.log("props::2022-08-16", props);

    const app = props.app;
    const redirect = Redirect.create(app);
  
    const url = "/api/v0/shopify/billing/trigger_plan_2";
    let myUrl = new URL(url, BASE_URL_RAILS);
  
    await getSessionToken(app).then((data) => {
      let token = data;
  
      var data = JSON.stringify({});
      
      var config = {
        method: 'post',
        url: myUrl,
        headers: { 
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        // console.log('response:::2022-08-18', response);
        let payload = response.data.payload;
        redirect.dispatch(Redirect.Action.REMOTE, payload.confirmation_url);
  
  
      })
      .catch(function (error) {
        console.log(error);
      });
  
    });
  
  }




  var plans = {};
  plans.triggerPlan1 = triggerPlan1;
  plans.triggerPlan2 = triggerPlan2;

  return (
    <>
      <Template
        app={app}
        navigate={navigate}
        params={params}
        location={location}

        isLoading={isLoading}
        setLoading={setLoading}

        billingActive={billingActive}
        setBillingActive={setBillingActive}

        plan={plan}
        plans={plans}
        store={store}

      ></Template>
    </>
  );

}

export default Billing;