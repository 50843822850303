import React, { useEffect, useState } from "react";

import { getSessionToken } from "@shopify/app-bridge-utils";
import axios from "axios";
import Template from "./Template";
import { 
  useNavigate,
} from "react-router-dom";


const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;



function NavMenu(props) {
  // console.log("NavMenu::2022-09-22");
  // console.log("props::2022-09-22", props);
  const app = props.app;
  const navigate = useNavigate();
  


  
  // State variables:
  const [isLoading, setLoading] = useState(true);
  const [store, setStore] = useState(null);
  const [plan, setPlan] = useState(null);



  useEffect(() => {
    getStore();
    setLoading(false);

    async function getStore() {
      // console.log("checkBillingPlan()::2022-06-09");
      const url = "/api/v0/shopify/page_contents/navbar";
      let myUrl = new URL(url, BASE_URL_RAILS);
    
      await getSessionToken(app).then((data) => {
        let token = data;
        var data = JSON.stringify({});
        
        var config = {
          method: 'get',
          url: myUrl,
          headers: { 
            Authorization: `Bearer ${token}`,
          }
        };
        
        axios(config)
        .then(function (response) {
          // console.log("response::2022-08-18", response);
          let payload = response.data.payload;
          // console.log('payload::2022-06-09', payload);
          setStore(payload.store);

          // Recurring application charge:
          setPlan(payload.recurring_application_charge);
  
        })
        .catch(function (error) {
          console.log(error);
        });
    
      });
  
    }

  }, []);




  return (
    <Template
      isLoading={isLoading}
      plan={plan}
      store={store}
      navigate={navigate}
    ></Template>
  );
}



export default NavMenu;
