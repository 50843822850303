import React from "react";

import {
  Button,
  ButtonGroup,
  Card,
  Form,
  FormLayout,
  Layout,
  TextField,
} from "@shopify/polaris";



const BASE_URL_RAILS = process.env.REACT_APP_BASE_URL_RAILS;
const BASE_URL_MINTER_FRONT = process.env.REACT_APP_BASE_URL_MINTER_FRONT;



function ClaimLink(props) {
  const app = props.app;
  const store = props.store;
  const order = props.order;
  const product = props.product;
  const resetClaimStatus = props.resetClaimStatus;

  var emailCount = product.certify_claim_email_count;



  const formEmail = props.formEmail;
  const handleFormSubmit = props.handleFormSubmit;
  const handleFormEmailChange = props.handleFormEmailChange;



  return (
    <Layout.Section>
      <Card title="Claim Link" sectioned>



        <>
          <p><b>Claim status:</b> { product.payload_mint === null ? "Pending" : "Claimed" }</p>
        </>



        <>
          <p><b>Emails sent:</b> { emailCount }</p>
        </>




        <p><b>Claim link: </b> <a
          href={`${BASE_URL_MINTER_FRONT}/minter?uuid=${product.uuid}`}
          target="_blank"
        >
          { `${BASE_URL_MINTER_FRONT}/minter?uuid=${product.uuid}` }
        </a></p>

        <div style={{paddingTop: '15px'}}>
          <Form onSubmit={(_event) => handleFormSubmit(_event, product.uuid)}>
            <FormLayout>

              <TextField
                value={formEmail}
                onChange={handleFormEmailChange}
                label="Email recipient"
                name="formEmail"
                type="email"
                helpText={
                  <span>
                    We'll use this email address to send the NFT claim link.
                  </span>
                }
              />

              <Button submit>Send NFT Claim Email</Button>
            </FormLayout>
          </Form>
        </div>

          {/* <Button 
            destructive
            onClick={() => resetClaimStatus()}
          >Reset Claim Status</Button> */}

      </Card>
      <div style={{height: '50px'}}></div>
    </Layout.Section>
  );

}



export default ClaimLink;