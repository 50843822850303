import React from "react";
import {
  Layout,
  Page,
  Stack,
} from '@shopify/polaris';

// Components:
import NavMenuItem from "./NavMenuItem";

// CSS:
import styles from "./css/Template.module.css";



function Template(props) {
  // console.log("Template::2022-06-02");
  // console.log("props::2022-06-02", props);
  const isLoading = props.isLoading;
  const plan = props.plan;
  const store = props.store;
  const navigate = props.navigate;




  if (isLoading) return <></>;
  if (store === null) return <></>;


  return (
    <Page>
      <Layout>
        <Layout.Section>
          <PlanChecker
            plan={plan}
            store={store}
            navigate={navigate}
          ></PlanChecker>
          <hr style={{marginTop: 'unset'}}></hr>
        </Layout.Section>
      </Layout>
    </Page>
  );
}



function PlanChecker(props) {
  const plan = props.plan;
  const store = props.store;

  if ([1, 2, 3].includes(store.id)) {
    return (
      <PlanActive></PlanActive>
    )      
  }

  if (['', null].includes(plan)) {
    return (
      <PlanInactive></PlanInactive>
    )      
  }

  if (['Starter Plan', 'Business Plan'].includes(plan)) {
    return (
      <PlanActive></PlanActive>
    )    
  }
}




function PlanActive(props) {
  // console.log("NavMenu::2022-06-02");
  // console.log("props::2022-06-02", props);

  return (
    <Stack alignment="center">
      {/* <Stack.Item fill> */}
        {/* <Heading>{ pageHeading }</Heading> */}
      {/* </Stack.Item> */}

      <Stack.Item>
        <NavMenuItem name={'Dashboard'} path={'/'}></NavMenuItem>
      </Stack.Item>

      <Stack.Item>
        <NavMenuItem name={'Test Collectibles'} path={'/test_nft'}></NavMenuItem>
      </Stack.Item>

      <Stack.Item>
        <NavMenuItem name={'Smart Contract'} path={'/smart_contract'}></NavMenuItem>
      </Stack.Item>

      <Stack.Item>
        <NavMenuItem name={'Collectibles Settings'} path={'/nft_settings'}></NavMenuItem>
      </Stack.Item>

      <Stack.Item>
        <NavMenuItem name={'Product Setup'} path={'/product_setup'}></NavMenuItem>
      </Stack.Item>

      <Stack.Item>
        <NavMenuItem name={'New Orders'} path={'/new_orders'}></NavMenuItem>
      </Stack.Item>

      <Stack.Item>
        <NavMenuItem name={'Billing'} path={'/billing'}></NavMenuItem>
      </Stack.Item>

    </Stack>
  );
}



function PlanInactive(props) {
  // console.log("NavMenu::2022-06-02");
  // console.log("props::2022-06-02", props);

  return (
    <Stack alignment="center">

      <Stack.Item>
        <NavMenuItem name={'Dashboard'} path={'/'}></NavMenuItem>
      </Stack.Item>

      <Stack.Item>
        <NavMenuItem name={'Test Collectibles'} path={'/test_nft'}></NavMenuItem>
      </Stack.Item>

      {/* <Stack.Item>
        <NavMenuItem name={'Smart Contract'} path={'/'}></NavMenuItem>
      </Stack.Item> */}

      {/* <Stack.Item>
        <NavMenuItem name={'Collectibles Settings'} path={'/'}></NavMenuItem>
      </Stack.Item> */}

      {/* <Stack.Item>
        <NavMenuItem name={'Product Setup'} path={'/'}></NavMenuItem>
      </Stack.Item> */}

      {/* <Stack.Item>
        <NavMenuItem name={'New Orders'} path={'/'}></NavMenuItem>
      </Stack.Item> */}

      <Stack.Item>
        <NavMenuItem name={'Billing'} path={'/billing'}></NavMenuItem>
      </Stack.Item>

    </Stack>
  );
}

export default Template;
